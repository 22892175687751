import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section404 from "../components/es/section404"
import DonDelicja from "../components/don-delicja"
import Cookies from "../components/es/cookies"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" lang="es" />
    <RelativeContainer>
      <Section404 id="hola" className="scroll-section"></Section404>
      <DonDelicja className="fix-secound-section" />
    </RelativeContainer>
    <Cookies />
  </Layout>
)

const RelativeContainer = styled.div`
  position: relative;
`

export default NotFoundPage
